import axios from 'axios'
// import QS from 'qs';
// import { Toast } from 'vant';
import Notify from "vant/lib/notify";
let baseURL = ''
if (process.env.VUE_APP_NODE_ENV == 'production') {
  baseURL = 'https://api.getpassportphoto.online'
} else {
  baseURL = 'https://api.getpassportphoto.online/stage'
}
axios.defaults.baseURL = baseURL
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(config => {
  // 每次发送请求之前判断vuex中是否存在token        
  // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
  // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
  // const token = store.state.token;
  // token && (config.headers.Authorization = token);
  // console.log(config)
  return config;
},
  error => {
    return Promise.error(error);
  }
)

//响应拦截
axios.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
    // 否则的话抛出错误
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  error => {
    if (error.response.status) {
      console.log(error, 'error')
      Notify({
        type: "danger",
        message: 'network error',
      });
      return Promise.reject(error.response);
    }
  }
)


export default axios