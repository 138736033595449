import axios from '@/utils/request.js'
const getCountriesUrl = '/getCountries'





export function getCountries() {
  return axios({
    method: 'post',
    data: {
      country: 'India'
    },
    url: getCountriesUrl
  });
}

